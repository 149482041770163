.nav a.nav-link.active {
  color: var(--primary-color);
}

.row [class*="col-"] p,
.row [class*="col-"]:first-child p {
  margin: 0;
}

.nav-main a:hover {
  text-decoration: none;
}

a .fa-external-link-alt {
  display: none;
  margin-left: 4px;
}

a:hover .fa-external-link-alt {
  display: inline-block;
}

label.dark,
p.dark {
  font-weight: bold;
}

p.heading,
.text-lg {
  font-size: var(--font-size-heading);
}

.text-sm {
  font-size: var(--font-size) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

label.light {
  font-size: var(--font-size);
  color: var(--font-light);
}

label.lighter {
  font-size: var(--font-size);
  color: var(--font-light);
  font-weight: lighter;
}

.help-warning {
  color: var(--warning-dark);
  font-size: 14px;
}

button,
a.btn,
button.btn {
  border-radius: var(--radius);
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.pb-mid {
  padding-bottom: var(--mid-spacing);
}

.pt-mid {
  padding-top: var(--mid-spacing);
}

.dropdown-menu {
  font-size: var(--font-size);
  font-weight: normal;
  color: var(--font-color);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary-color);
  color: var(--white);
}

.dropdown-item {
  color: var(--font-color);
}

/* ========== Override some grid settings ============ */

@media (min-width: 768px) and (max-width: 979px) {
  .container,
  .navbar-fixed-top .container,
  .navbar-fixed-bottom .container {
    width: 100%;
  }
}

.max-width-container {
  max-width: 1200px;
}

.full-height-container {
  height: calc(100vh - calc(var(--top-bar-height) + 65px));
}

.h-100 {
  height: 100%;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  box-sizing: border-box !important;
}

.border-bottom-1 {
  border-bottom: 1px solid var(--light-gray);
}

.border-bottom-light-1 {
  border-bottom: 1px solid var(--smooth-gray);
}

.border-top-1 {
  border-top: 1px solid var(--light-gray);
}

.border-top-light-1 {
  border-top: 1px solid var(--smooth-gray);
}

.border-left-1 {
  border-left: 1px solid var(--light-gray);
}

.border-left-light-1 {
  border-left: 1px solid var(--smooth-gray);
}

.border-right-1 {
  border-right: 1px solid var(--light-gray);
}

.border-right-light-1 {
  border-right: 1px solid var(--smooth-gray);
}

/* ============ Overrides for card component ============ */

.card {
  border-color: var(--light-gray);
}

.card-header {
  border-color: var(--light-gray);
  background-color: transparent;
}

/* ======================== */

blockquote {
  opacity: 0.8;
  border-left: 4px solid var(--primary-color);
  padding: var(--lg-spacing);
}

.p-lg {
  padding: var(--lg-spacing);
}

.pl-lg {
  padding-left: var(--lg-spacing);
}

.popover {
  font-family: "Gotham Book", "Helvetica Neue", "Arial", sans-serif;
  border-color: var(--darker-bg);
  font-size: var(--font-size);
}

.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::before {
  border-top-color: var(--darker-bg);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: var(--darker-bg);
}

.bs-popover-right .arrow::before {
  border-right-color: var(--darker-bg);
}

.bs-popover-right .arrow::after {
  border-left-color: var(--darker-bg);
}

.bs-popover-left .arrow::before {
  border-left-color: var(--darker-bg);
}

.bs-popover-left .arrow::after {
  border-right-color: var(--darker-bg);
}

.popover-header {
  background-color: var(--darker-bg);
  color: white;
  font-weight: 600;
}

a.hint {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2a3352;
  border-radius: 50%;
  padding: 4px;
  font-size: 10px;
  color: #2a3352;
  height: 16px;
}

.monthly-production form,
.production form {
  float: none;
  margin: 0;
}

.select2-container-disabled.select2-container .select2-choice {
  background-color: var(--disabled-color);
  color: var(--disabled-text-color);
}

.top-most-container {
  margin-top: -20px;
}

.br-light-gray {
  border-right: 1px solid var(--light-gray);
}

.bb-light-gray {
  border-bottom: 1px solid var(--light-gray);
}

.c-primary {
  color: var(--primary-color) !important;
}

.insured-extra-info {
  padding: var(--mid-spacing);
  border: 1px solid var(--primary-color);
  border-radius: var(--radius);
  background-color: var(--primary-color-004);
  color: var(--dark-gray);
}

.collection-item {
  border: 1px var(--light-gray) solid;
  border-radius: var(--radius);
  padding: var(--mid-spacing);
  margin: var(--mid-spacing);
  position: relative;
}

.collection-item.collection-item--plain {
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  background: transparent !important;
}

.box-container {
  border: 1px var(--light-gray) solid;
  border-radius: var(--radius);
  padding: var(--gutter-w);
  padding-top: calc(var(--gutter-w) - 2px);
  margin: var(--mid-spacing);
  position: relative;
}

.box-container--bg,
.collection-item--bg {
  background: var(--light-gray-bg) !important;
}

.box-container--no-bg,
.collection-item--no-bg {
  background: transparent !important;
}

.box-container--no-border,
.collection-item--no-border {
  border: none;
}

.collection-item .collection-item,
.box-container .box-container {
  margin: 0;
}

.collection-item .col-delete {
  padding-top: 37px;
}

.box-container .dependent-fields {
  margin-top: -8px;
}

.collapsible-header {
  position: relative;
}

.collection-item > button.close,
.collapsible-header > button.close,
.collapse .collection-item > button.close {
  z-index: 4;
  position: absolute;
  top: 10px;
  right: 10px;
}

.collection-inline .collection-item.collection-border-top {
  padding-bottom: 10px !important;
  margin-bottom: 0 !important;
}

.collection-inline .collection-border-top + .collection-border-top {
  border-top: 1px solid var(--light-gray) !important;
  padding: 10px 0;
}

.carriers-collection fieldset.form-group {
  margin-bottom: 0;
}

.address-description-container {
  padding-right: 30px;
}

.collapsable button.close,
.collapse button.close {
  position: absolute;
  top: 0;
  right: 10px;
}

.address-description-container > button.close {
  top: 0;
  right: 0;
}

.builders-risk-locations .map-container,
.classification-locations .map-container,
.property-locations .map-container {
  width: 100%;
  height: 280px;
}

.map-expand:checked ~ .map-container {
  height: 600px;
}

.map-wrapper {
  position: relative;
}

.map-expand {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  cursor: pointer;
  position: absolute;
  top: -12px;
  right: 0;
  z-index: 4;
  transform: translateX(17px);
  border: none;
}

.map-expand:hover {
  border: none;
}

.map-expand::before {
  display: block;
  font-family: 'Font Awesome 5 Free', sans-serif;
  content: "\f424";
  width: 29px;
  height: 29px;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 100px;
  line-height: 29px;
  background-color: var(--primary-color);
  font-weight: 700;
  text-align: center;
}

.map-expand:checked::before {
  content: "\f422";
}

.insured-dropdown.with-new .select2-results {
  padding-bottom: 0;
  position: static;
}

.select2-create-button {
  padding: 10px;
  border-top: 1px solid var(--light-gray);
  background: var(--light-gray-bg);
  position: sticky;
  bottom: 0;
}

.insured-dropdown.with-new .select2-results li:has([data-id="new"]) {
  background: var(--white-bg);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid var(--light-gray);
  bottom: -42px;
  box-shadow: var(--shadow);
  color: #fff;
  height: 43px;
  padding: 0;
  position: absolute;
  width: calc(100% + 2px);
  z-index: 100;
  left: -1px;
}

.insured-dropdown.with-new .select2-results li:has([data-id="new"]) div {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background: var(--white-bg);
  color: var(--font-light);
  padding: 6px 10px 6px 13px;
}

.insured-dropdown.with-new .select2-results li:has([data-id="new"]),
.insured-dropdown.with-new .select2-results li:has([data-id="new"]) div {
  pointer-events: none;
}

.insured-dropdown.with-new .select2-results li:has([data-id="new"]) button {
  pointer-events: auto;
}

.btn.btn-fab {
  border-radius: 50%;
  width: 29px;
  height: 29px;
  line-height: 29px;
  text-align: center;
  padding: 0;
}

.absolute-top-left {
  position: absolute;
  left: -12px;
  top: -12px;
}

.btn-fab .fa-plus,
.btn-fab .fa-minus {
  cursor: pointer;
  pointer-events: none;
}

.collapse {
  overflow: visible !important;
}

.collapse-small {
  transform: scale(0.8);
}

.collection-item .collection-item {
  background-color: var(--light-gray-bg);
}

.bg-white {
  background-color: var(--white-bg);
}

.bg-gray {
  background-color: var(--light-gray-bg) !important;
}

.bg-dark-gray {
  background-color: var(--side-nav-gray);
}

.alert-danger {
  color: var(--font-color);
}

.alert-dark {
  color: var(--font-dark);
}

.alert-padding .alert {
  margin: 0 0 20px 0 !important;
}

.top-actions {
  border-bottom: none;
}

.table-locations {
  max-height: 400px;
  overflow-y: auto;
}

.agency-link-placeholder {
  display: inline-block;
  width: 100px;
  background-color: var(--primary-color);
  border-radius: 5px;
  opacity: 0.2;
}

.placeholder-line {
  display: block;
  background-color: #eee;
  border-radius: 5px;
  margin-bottom: 1px !important;
}

.placeholder-line-link {
  background-color: var(--primary-color);
  opacity: 0.2;
}

/* ApplicationV3 side panel */
.summary-sidebar {
  border-left: 1px solid var(--light-gray);
  padding: 0 !important;
}

.summary-sidebar h3 {
  padding: var(--lg-spacing);
  margin-bottom: 0;
}

.new-ui .form-footer {
  background-color: var(--white-bg);
  position: relative;
  border-top: 1px solid var(--light-gray);
}

.summary-section > a {
  padding: var(--mid-spacing);
  border-top: 1px solid var(--light-gray);
  border-bottom: 1px solid var(--light-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--thin-spacing);
  width: 100%;
  flex: 1;
  text-decoration: none;
}

.summary-section > a .toggle-icon::before {
  font-family: 'Font Awesome 5 Free', sans-serif;
  font-style: normal;
  content: "\f107";
  font-weight: 700;
}

.summary-section > a.collapsed .toggle-icon::before {
  content: "\f105";
}

.summary-item {
  padding: var(--thin-spacing);
  margin-top: var(--mid-spacing);
  padding-bottom: var(--lg-spacing);
  padding-left: calc(var(--lg-spacing) * 1.7);
}

.summary-item .summary-icon {
  position: absolute;
  left: 12px;
  font-size: 16px;
}

.summary-item p {
  line-height: 1.2rem;
}

.summary-item:not(:last-child) {
  border-bottom: 1px solid var(--light-gray);
}

.right-icon {
  position: absolute;
  right: 15px;
  font-size: 14px;
  top: calc(16px - 7px);
  color: var(--smooth-gray);
}

.new-ui th label,
.new-ui td label {
  margin-bottom: 0;
}

.html-preview {
  border-radius: var(--radius);
  background-color: var(--input-bg);
  border: 1px solid var(--light-gray);
  white-space: pre-wrap;
}

.html-preview p {
  padding-bottom: 1em;
}

.gl-menu > li > ul > li {
  list-style: none;
}

.border-primary-1 {
  border: 1px solid var(--primary-color);
}

.border-radius-5 {
  border-radius: 5px;
}

/* ================ quote =================== */

.diligent-effort-skipped {
  display: none;
}

.form-control[readonly].lob-premium,
.form-control[readonly].lob-premium:hover {
  border: none;
  text-align: left;
  padding: 0;
  pointer-events: none;
  background-color: #fff;
}

label[for=product_general_liability_amount].required::before {
  content: '';
}

.classification .close {
  z-index: 1;
}

.form-search-input {
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!-- Font Awesome Pro 5.15.4 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath  fill='rgb(75, 81, 103)' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'/%3E%3C/svg%3E");
  background-position: right 10px center;
  background-size: 12px 12px;
  background-repeat: no-repeat;
}

.form-search-results {
  max-height: 300px;
  overflow-y: scroll;
}

.program-policy-forms .form-search-results {
  max-height: inherit;
  overflow-y: auto;
}

.form-search-results.scrollable {
  max-height: 30vh;
  overflow-y: scroll;
}

.program-policy-forms .scroll-area {
  max-height: 67vh;
  overflow-y: scroll;
}

#free-text-editor iframe {
  width: 100%;
  height: 100%;
  min-height: 500px;
}

#free-text-editor textarea {
  width: 100%;
}

/* ================ standout section =================== */

.standout {
  background-color: var(--light-gray-bg);
  padding: 1rem;
  margin: 1rem;
}

/* ================ claim modal overrides =================== */
.modal-claims.modal-large {
  max-width: 700px;
  margin-left: calc(50% + -350px) !important;
}

.modal-claims .form-horizontal input[type="text"],
.modal-claims .form-horizontal input[type="number"],
.modal-claims .form-horizontal textarea,
.modal-claims .form-horizontal .select2-container,
.vertical-form input[type="text"],
.vertical-form input[type="number"],
.vertical-form input[type="date"],
.form-vertical input[type="text"],
.form-vertical input[type="number"],
.form-vertical input[type="date"],
.form-vertical input[type="file"],
.form-vertical textarea,
.vertical-form textarea,
.vertical-form .select2-container,
.vertical-form .ts-wrapper {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .vertical-form input.field-sm[type="text"],
  .vertical-form input.field-sm[type="number"],
  .vertical-form input.field-sm[type="date"],
  .form-vertical input.field-sm[type="text"],
  .form-vertical input.field-sm[type="number"],
  .form-vertical input.field-sm[type="date"],
  .vertical-form .field-sm.select2-container,
  .vertical-form .field-sm.ts-wrapper,
  .form-vertical .field-sm.select2-container,
  .form-vertical .field-sm.ts-wrapper {
    width: calc(50% - 15px);
    min-width: calc(50% - 15px);
    max-width: calc(50% - 15px);
  }

  .vertical-form input.field-x-sm[type="text"],
  .vertical-form input.field-x-sm[type="number"],
  .vertical-form input.field-x-sm[type="date"],
  .form-vertical input.field-x-sm[type="text"],
  .form-vertical input.field-x-sm[type="number"],
  .form-vertical input.field-x-sm[type="date"],
  .vertical-form .field-x-sm.select2-container,
  .vertical-form .field-x-sm.ts-wrapper,
  .form-vertical .field-x-sm.select2-container,
  .form-verticalm .field-x-sm.ts-wrapper {
    width: calc(25% - 15px);
    min-width: calc(25% - 15px);
    max-width: calc(25% - 15px);
  }

  .vertical-form .full-width-fields input.field-sm[type="text"],
  .vertical-form .full-width-fields input.field-sm[type="number"],
  .vertical-form .full-width-fields input.field-sm[type="date"],
  .form-vertical .full-width-fields input.field-sm[type="text"],
  .form-vertical .full-width-fields input.field-sm[type="number"],
  .form-vertical .full-width-fields input.field-sm[type="date"],
  .vertical-form .full-width-fields .field-sm.select2-container,
  .vertical-form .full-width-fields .field-sm.ts-wrapper,
  .form-vertical .full-width-fields .field-sm.select2-container,
  .form-vertical .full-width-fields .field-sm.ts-wrapper,
  .vertical-form .full-width-fields input.field-x-sm[type="text"],
  .vertical-form .full-width-fields input.field-x-sm[type="number"],
  .vertical-form .full-width-fields input.field-x-sm[type="date"],
  .form-vertical .full-width-fields input.field-x-sm[type="text"],
  .form-vertical .full-width-fields input.field-x-sm[type="number"],
  .form-vertical .full-width-fields input.field-x-sm[type="date"],
  .vertical-form .full-width-fields .field-x-sm.select2-container,
  .vertical-form .full-width-fields .field-x-sm.ts-wrapper,
  .form-vertical .full-width-fields .field-x-sm.select2-container,
  .form-vertical .full-width-fields .field-x-sm.ts-wrapper {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

.form-vertical .input-group input[type="text"],
.vertical-form .input-group input[type="text"] {
  width: 1%;
  min-width: 0;
}

.modal-claims .form-horizontal .control-label,
.vertical-form label {
  float: none;
  width: 100%;
  padding-top: 0;
}

.vertical-form label.custom-file-label {
  padding-top: 0.375em;
}

.vertical-form .switch-field label {
  padding: 3px 0;
  float: left;
}

.modal-claims .form-horizontal .controls {
  margin: 0;
}

.modal-claims .datepicker .controls {
  border: 1px solid var(--light-gray);
  display: flex;
  border-radius: var(--radius);
}

.modal-claims .clear-wrap {
  flex-grow: 1;
}

.modal-claims .datepicker .clear {
  top: 0;
}

.modal-claims .calendar-container {
  padding: 0 10px;
}

.modal-claims textarea {
  border: 1px solid var(--light-gray);
  border-radius: var(--radius);
  background: transparent;
}

.modal-claims .collection-item {
  margin: 0 0 12px 0;
  padding: 0;
  border: none;
}

.modal-claims .collection-item .control-group {
  margin-bottom: 0;
}

.modal-claims .collection-item .lawsuit-delete {
  line-height: 24px;
}

.modal-application-change-status .modal-body {
  overflow-y: visible !important;
}

.datepicker input[type="text"],
.datepicker input[readonly] {
  border-radius: var(--radius);
  border: 1px solid var(--light-gray);
  max-width: calc(80% - 32px);
  min-width: calc(80% - 32px);
}

.accounting-bank-reconciliation div.summary h3,
.accounting-journal-details div.summary h3,
.accounting-payment-details div.summary h3,
.accounting-transaction-details div.summary h3,
.form-edit-abacus-posting-period div.summary h3 {
  font-size: 1.5em;
  font-family: Gotham Book, sans-serif;
  color: #000;
  letter-spacing: 0;
  margin: 0 0 var(--lg-spacing) 0;
  border: none;
  padding: 0;
}

.accounting-bank-reconciliation .name-val label,
.accounting-journal-details .name-val label,
.accounting-payment-details .name-val label,
.accounting-transaction-details .name-val label,
.accounting-bank-reconciliation ul.name-val,
.accounting-journal-details ul.name-val,
.accounting-payment-details ul.name-val,
.accounting-transaction-details ul.name-val,
.form-edit-abacus-posting-period ul.name-val {
  font-size: var(--font-size);
  font-weight: var(--font-weight);
}

.accounting-bank-reconciliation .control-group,
.accounting-journal-details .control-group,
.accounting-payment-details .control-group,
.accounting-transaction-details .control-group,
.accounting-offset-details .control-group {
  padding-left: 0;
  font-size: var(--font-size);
}

.accounting-bank-reconciliation ul.name-val li,
.accounting-journal-details ul.name-val li,
.accounting-payment-details ul.name-val li,
.accounting-journal-details ul.name-val li,
.accounting-transaction-details ul.name-val li,
.form-edit-abacus-posting-period ul.name-val li {
  padding: 0 0 var(--mid-spacing) 0;
}

.row-inset,
.row-inset > [class*="col-"]:first-child,
.row-inset > [class*="span"]:first-child {
  border-color: var(--light-gray);
}

/* ====== Overrides for tabs ======= */

.tab-content .nav-tabs {
  margin: var(--mid-spacing) 0 -1px 0;
}

.tab-content .nav-tabs a:not(.active):hover {
  text-decoration: none;
  color: var(--primary-color);
}

.tab-content .nav-tabs li a {
  color: var(--font-light);
  font-size: var(--font-size-heading);
  line-height: 14px;
  padding: var(--mid-spacing) 25px 0 25px;
  background-color: transparent;
  margin-right: 0;
}

.tab-content .nav-tabs .active {
  border-bottom: 3px solid var(--primary-color);
  color: var(--dark-gray);
}

.row [class*="col-"]:first-child .alert-summary,
.row [class*="span"]:first-child .alert-summary {
  margin: 0 0 20px 0;
}

.border {
  border-top: 1px solid var(--light-gray);
}

.border-top {
  border-top: 1px solid var(--light-gray);
}

.table-report tr:nth-child(2n) > td {
  background-color: transparent;
}

.customer-inline {
  display: inline-flex;
}

.customer-badge {
  flex-shrink: 0;
}

.emailed-badge {
  margin: calc(var(--thin-spacing) / 2) var(--thin-spacing) 0 0;
  background-color: var(--light-gray);
}

.emailed-badge,
.emailed-badge .svg-inline--fa {
  font-size: var(--small-font-size);
  color: var(--font-light);
}

.padded-col {
  padding: 20px 15px 0 15px !important;
}

.name-val .avatar img {
  border-radius: 100px;
}

.payment-entries .sign-negative .input-prepend::before {
  left: 22px !important;
}

.payment-entries .sign-negative .input-prepend::after {
  right: 4px !important;
}

.payment-entries .input-prepend input {
  line-height: 1.5em;
}

.sticky-under-header {
  top: var(--top-bar-height);
}

.sticky-to-bottom {
  position: sticky;
  bottom: 0;
  z-index: 2;
}

.key-val-table td {
  padding-bottom: var(--lg-spacing);
  vertical-align: top;
}

.key-val-table tr td:first-of-type {
  font-weight: var(--font-weight-bold);
  padding-right: var(--lg-spacing);
}

/* ============== cost center selector ================== */

.form-control.cost-centre-display-name {
  background-color: #fff;
}

table .cost-centre .action-collapse {
  display: none;
}

table .cost-centre.expanded .action-expand {
  display: none;
}

table .cost-centre.expanded .action-collapse {
  display: inline;
}

/* ============== contractors guard ================== */

.cg-quick-quote .cg-premium {
  font-size: 1.4em;
}

.cg-quick-quote ul {
  list-style-type: none;
}

.cg-quick-quote ul li {
  margin-left: 10px;
  border-bottom: 1px #db4646 solid;
  padding: 5px 0 5px 0;
}

.cg-quick-quote ul li:first-child {
  padding-top: 0;
}

.cg-quick-quote ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

/* ============== intab ajax ================== */

.intab-exception {
  padding: 30px 20px 10px 20px;
}

/* =============  quote multi select =============== */

.quote-multi-select {
  float: left;
  margin-right: 10px;
}

/* =============  quote limit controls =============== */

.quote-limit__item,
.label-space__item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.quote-limit__item .form-group,
.label-space__item .form-group {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* ============= Table Listing forms -  overrides bootstrap =============== */
.table-listing .collection-item .input-group {
  flex-wrap: nowrap;
}

.table-listing .collection-item .input-group input {
  box-sizing: border-box;
}

.table-listing .collection-item .controls {
  margin-left: 0;
}

.table-listing .collection-item .input-group-text {
  line-height: 14px;
}

.table-listing .collection-item .delete .btn,
.table-report .collection-item .delete .btn {
  padding: 6px 8px;
}

.table-listing .collection-item .select2-container,
.table-report .collection-item .select2-container,
.table-listing .collection-item .ts-wrapper,
.table-report .collection-item .ts-wrapper {
  width: 250px;
  max-width: 250px;
}

.table-listing-tools .sidebar-filters .control-group .controls > input {
  width: 100%;
}

.form-new-gl-dump .modal-body {
  padding-right: 20px;
  padding-bottom: 20px;
}

.form-abacus-cheque-batch-new[name="abacus_cheque_batch"] .col-12 > fieldset.form-group,
.form-abacus-cheque-batch-new[name="abacus_cheque_batch"] .col-12 > #abacus-cheque-batch-select-all fieldset.form-group,
#abacus_cheque_batch_items .form-group {
  margin-bottom: 0;
}

/* =============  Line Items As Collected Report =============== */
.form-line-items-as-collected-report .control-group {
  padding-left: 0;
}

.form-line-items-as-collected-report label.required {
  display: inline-flex;
}

.form-line-items-as-collected-report .row-submit .col-12 {
  padding: 0 15px 15px 15px !important;
}

#line-items-as-collected-report-table {
  border-top: 1px solid var(--light-gray);
}

div.disabled .ts-control {
  background-color: var(--disabled-color);
  border: 1px #ddd solid;
  opacity: 1;
  color: var(--disabled-text-color);
}

/* =========== inline collection items ============ */
.collection-inline .collection-item {
  border: none;
  padding: 0;
}

.hidden-headers div.collection-item:nth-of-type(1) .collection-inline-headers,
.labels-only-first div.collection-item:nth-of-type(1) .d-md-none {
  display: block !important;
}

.labels-only-first div.collection-item:nth-of-type(1) .d-md-none.required {
  display: inline-flex !important;
}

.labels-only-first div.collection-item:nth-of-type(1) .collection-item-actions {
  padding-top: 2.4rem;
}

.collection-inline-headers {
  font-size: 12px;
  line-height: 1.5;
  color: var(--font-color);
}

.with-empty-message + .empty-message {
  display: none;
  font-size: var(--font-size-heading);
}

.with-empty-message:empty + .empty-message {
  display: block;
}

.underwriting-list-wrapper {
  height: 62vh;
  overflow-y: scroll;
}

/* Make email preview more like wysiwyg */
.email-preview {
  padding: 10px 20px;
  padding-bottom: 5px;
  white-space: normal;
}

.email-preview div,
.email-preview p,
.email-preview ul,
.email-preview ol,
.email-preview table,
.email-preview dl,
.email-preview blockquote,
.email-preview pre,
.email-preview h1,
.email-preview h2,
.email-preview h3,
.email-preview h4,
.email-preview h5,
.email-preview h6 {
  font-family: Arial, Helvetica, Verdana, Tahoma, sans-serif;
}

.email-preview code,
.email-preview pre {
  font-family: Menlo, Monaco, monospace, sans-serif;
}

.email-preview div,
.email-preview p,
.email-preview ul,
.email-preview ol,
.email-preview table,
.email-preview dl,
.email-preview blockquote,
.email-preview pre {
  font-size: 14px;
  line-height: 1.6em;
}

.email-preview a {
  color: #15c !important;
  text-decoration: underline !important;
}

/*
    TYPOGRAPHY
*/
.email-preview object,
.email-preview embed,
.email-preview video,
.email-preview img {
  max-width: 100%;
  width: auto;
}

.email-preview video,
.email-preview img {
  height: auto;
}

.email-preview div,
.email-preview p,
.email-preview ul,
.email-preview ol,
.email-preview table,
.email-preview dl,
.email-preview blockquote,
.email-preview pre {
  margin: 0;
  margin-bottom: 10px !important;
  border: none;
  background: none !important;
  box-shadow: none !important;
}

.email-preview iframe,
.email-preview object,
.email-preview hr {
  margin-bottom: 15px !important;
}

.email-preview blockquote {
  margin-left: 1.5em !important;
  padding-left: 0 !important;
  color: #777;
  font-style: italic !important;
}

.email-preview ul,
.email-preview ol {
  padding-left: 2em !important;
}

.email-preview ul ul,
.email-preview ol ol,
.email-preview ul ol,
.email-preview ol ul {
  margin: 2px !important;
  padding: 0 !important;
  padding-left: 2em !important;
  border: none;
}

.email-preview dl dt {
  font-weight: bold;
}

.email-preview dd {
  margin-left: 1em;
}

.email-preview table {
  border-collapse: collapse;
  font-size: 1em !important;
}

.email-preview table td {
  padding: 5px !important;
  border: 1px solid #ddd;
  vertical-align: top;
}

.email-preview table thead td {
  border-bottom: 2px solid #000 !important;
  font-weight: bold !important;
}

.email-preview code {
  background-color: #d8d7d7 !important;
}

.email-preview pre {
  overflow: auto;
  padding: 1em !important;
  border: 1px solid #ddd !important;
  border-radius: 3px !important;
  background: #f8f8f8 !important;
  white-space: pre;
  font-size: 90% !important;
}

.email-preview hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
}

/*
    HEADERS
*/
.email-preview h1,
.email-preview h2,
.email-preview h3,
.email-preview h4,
.email-preview h5,
.email-preview h6 {
  margin-top: 0 !important;
  padding: 0 !important;
  background: none;
  color: #000;
  font-weight: bold;
}

.email-preview h1 {
  font-size: 36px !important;
  line-height: 1.111em !important;
  margin-bottom: 0.15em !important;
}

.email-preview h2 {
  font-size: 30px !important;
  line-height: 1.111em !important;
  margin-bottom: 0.25em !important;
}

.email-preview h3 {
  font-size: 24px !important;
  line-height: 1.333em !important;
  margin-bottom: 0.2em !important;
}

.email-preview h4 {
  font-size: 18px !important;
  line-height: 1.5em !important;
  margin-bottom: 0.2em !important;
}

.email-preview h5 {
  font-size: 1em !important;
  line-height: 1.6em !important;
  margin-bottom: 0.25em !important;
}

.email-preview h6 {
  font-size: 0.8em !important;
  line-height: 1.6em !important;
  text-transform: uppercase;
  margin-bottom: 0.3em !important;
}

/* ==== Filter Tabs ==== */
.filter-tabs .nav {
  display: flex;
}

.filter-tabs .nav-link {
  padding: calc(var(--thin-spacing) / 2) var(--mid-spacing);
  background-color: transparent;
  font-size: var(--font-size);
  line-height: 1.6;
}

.filter-tabs .nav-link.active {
  background-color: var(--primary-color);
  color: var(--white);
}

.filter-tabs .tab-content {
  padding: 0;
  background-color: transparent;
}

/* === Quote Templates === */

.quote-template-form-specification {
  display: none;
}

.quote-template-form-specification.selected {
  display: flex;
}

.quote-template-form-specification .handle {
  cursor: grab;
}

.quote-template-form-specification.sortable-drag {
  border: 1px #eee solid;
  background-color: #fff;
}

.quote-template-form-specification-other-form {
  min-height: calc(29px + 1rem);
}

.quote-template-form-specification-other-form .selected-text {
  display: none;
  color: var(--success-color);
}

.quote-template-form-specification-other-form.selected .selected-text {
  display: block;
}

.quote-template-form-specification-other-form.selected .select-button {
  display: none;
}

/* === Admin - Signature === */
.signature-row {
  border-bottom: 1px solid var(--light-gray);
}

.signature-row .settings-form .row {
  padding: 0;
}

.signature-image .dropzone-preview {
  margin-top: 0;
}

.signature-image .dropzone-preview-file:not(:empty) {
  text-align: left;
}

.signature-rows {
  margin: 20px 0;
}

.signature-rows .btn-delete {
  transform: scale(1.4) translateX(-1px);
}

.signature-rows div:nth-last-child(1 of .signature-row) {
  border: none;
  padding-bottom: 0;
}

.signature-image a,
.signature-rows .dropzone-preview-file:not(:empty) {
  text-align: center;
  border-radius: var(--radius);
  border: 1px solid var(--light-gray);
  display: inline-block;
}

.signature-image img,
.signature-rows .dropzone-preview-file img {
  width: auto;
  height: auto;
  max-height: 80px;
  max-width: 100%;
}

/* === Territory Controller === */

.product--commercial-property--territory [aria-disabled] {
  display: none;
}

/* ====== Template Fillable - Policy Forms ====== */

.preview-wrapper {
  background: rgb(50, 54, 57);
}

.preview-wrapper iframe {
  width: 100%;
  height: 100%;
  border: none;
}

img.preview-embed {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: auto;
}

.no-preview-wrapper {
  font-size: var(--large-font-size);
  line-height: 24px;
}

.unpreviewable-wrapper {
  font-size: var(--large-font-size);
  line-height: 24px;
}

.upload-wrapper .dropzone-placeholder {
  background-size: 40px 40px;
  padding-top: 60px;
}

.no-preview-content {
  background: url(../../../../../../../assets/img/doc-upload.svg)  no-repeat center top;
  padding-top: 100px;
}

.unpreviewable-content {
  background: url(../../../../../../../assets/img/unpreviewable.svg)  no-repeat center top;
  padding-top: 100px;
}

.no-preview-wrapper .fa-file-alt {
  width: 80px;
  height: auto;
  margin: 20px auto;
  font-size: 100px;
}

.existing-file a {
  color: var(--danger-color);
  font-size: 16px;
}

.existing-file .fa-file-alt {
  width: 12px;
  height: auto;
  font-size: 16px;
  margin-right: 5px;
}

.downloadable-unpreviewable {
  color: var(--font-color);
  text-decoration: underline;
}

.sortable-dragging > div {
  visibility: hidden;
}

.sortable-item {
  user-select: none;
}

/* === Accounts Forms === */
.form-new-abacus-account .account-division {
  display: none;
}

/* === Eligibility Forms === */
.form-eligibility-container {
  background-color: #fff;
  border-bottom: 1px solid var(--light-gray);
  position: sticky;
  top: var(--top-bar-height);
  z-index: 5;
}

.form-eligibility-container + .has-eligibility .side-navbar.sticky-under-header,
.form-eligibility-container + .has-eligibility .summary-sidebar .sticky-under-header {
  top: calc(var(--top-bar-height) + 93px);
}

.signature {
  height: auto;
  max-height: 200px;
  width: auto;
  max-width: 350px;
}

*[x-out-of-boundaries] {
  display: none !important;
}

.modal-dropdown {
  z-index: 1100;
}

.collection-item__preview {
  padding-bottom: var(--mid-spacing);
}

.collection-item__actions {
  position: absolute;
  top: 0;
  right: 12px;
  display: flex;
  justify-content: center;
}

.collection-item__actions .close {
  float: none;
  margin-top: 2px;
  padding: 10px 8px;
}

[data-show-hide-target="toggle"] {
  background: transparent;
  transition: var(--transition);
  color: var(--primary-color);
  padding: 10px 8px;
  line-height: 1rem;
  z-index: 1;
}

[data-controller~="show-hide"].is-collapsed:hover {
  border-color: var(--primary-color);
  cursor: pointer;
}

[data-controller~="show-hide"]:not([data-show-hide-icon-rotate-value="false"]).is-collapsed [data-show-hide-target="toggle"] {
  transform: rotate(180deg);
}

.table-rating-preview th {
  background: var(--light-gray-bg);
}

.align-with-input {
  line-height: 35px;
  padding-top: 24px;
}

.hide-behind-blur {
  position: relative;
}

.hide-behind-blur::before,
.hide-behind-blur::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hide-behind-blur::before {
  content: " ";
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(1.5px);
}

.hide-behind-blur::after {
  content: attr(data-message);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
}

.table-dimension-selector {
  border: 1px solid var(--light-gray);
  padding: 0.5rem;
  border-radius: var(--lg-radius);
  display: flex;
  flex-direction: column;
  transition: var(--transition);
}

.table-dimension-selector:has(input):hover,
.table-dimension-selector:has(input:checked) {
  border-color: var(--primary-color);
}

.modal-header .close-modal {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.modal-body.underwriting-rules-editor {
  max-height: 78vh;
}

.modal-body .calculation-modal-content {
  max-height: 27vh;
  overflow-y: scroll;
}

/* ====== Dynamic text replacement ====== */
.text-replacement {
  background: var(--blue-lighter);
  border: 1px solid var(--primary-color);
  border-radius: var(--radius);
  padding: 1rem 1rem 0 1rem;
}

.text-replacement__title {
  font-size: var(--font-size-heading);
}

.btn.text-replacement__item {
  color: var(--font-color);
  text-transform: none;
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  letter-spacing: 0;
}

/* ===== Password Field ====== */

.password-field .password-field__input {
  position: relative;
  margin-bottom: 3px;
}

.password-field .btn-icon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  height: 100%;
}

.password-field input {
  padding-right: 40px;
}

.password-field .password-field__input .form-control.is-invalid {
  background-position: right 30px center;
}

.password-field .invalid-feedback.mt-2 {
  margin-top: 0 !important;
}

.password-field .password-field__links a {
  font-size: var(--small-font-size);
  font-weight: var(--font-weight);
}

.password-field .password-field__links a:hover {
  text-decoration: none;
}

/* ============ Tom Select ================ */

.ts-control .has-description,
.ts-dropdown .has-description {
  position: relative;
}

.ts-control .description,
.ts-dropdown .description {
  opacity: 0.6;
}

.ts-control .has-description {
  width: 100%;
}

.ts-control .description {
  float: right;
}

.ts-dropdown .description {
  position: absolute;
  right: 25px;
}

.clippy {
  cursor: pointer;
}

.btn.clippy {
  width: 80px;
  text-align: center;
}

/* ============ Line Item Totals ================ */

.line-item-totals .line-item-totals__box {
  display: flex;
  padding: 1rem;
  align-items: center;
  background: var(--white-bg);
  border: 1px solid var(--primary-color);
  border-radius: var(--radius);
}

.lh-0-5 {
  line-height: 0.5rem !important;
}

.lh-1-2 {
  line-height: 1.2rem !important;
}

.lh-1-5 {
  line-height: 1.5rem !important;
}
