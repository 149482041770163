/* ========== Fonts ============= */
@font-face {
  font-family: Gotham;
  src: url('../../../../../../../../assets/fonts/Gotham-Light.otf');
  font-weight: 300;
}

@font-face {
  font-family: Gotham;
  src: url('../../../../../../../../assets/fonts/Gotham-LightItalic.otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url('../../../../../../../../assets/fonts/Gotham-Book.otf');
  font-weight: 400;
}

@font-face {
  font-family: Gotham;
  src: url('../../../../../../../../assets/fonts/Gotham-Medium.otf');
  font-weight: 700;
}

@font-face {
  font-family: Gotham Book;
  src: url('../../../../../../../../assets/fonts/Gotham-Light.otf');
}

@font-face {
  font-family: Gotham Black;
  src: url('../../../../../../../../assets/fonts/Gotham-Bold.otf');
}

body {
  color: var(--font-color);
}

body,
textarea,
button {
  font-family: Gotham, sans-serif;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--font-color);
  font-weight: normal;
}

h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light,
p.light {
  color: var(--font-light);
}

h1.dark,
h2.dark,
h3.dark,
h4.dark,
h5.dark,
h6.dark {
  font-weight: var(--font-weight-bold);
}

#main-column-container {
  padding-left: 0;
  padding-right: 0;
}

h1.lighter,
h2.lighter,
h3.lighter,
h4.lighter,
h5.lighter,
h6.lighter {
  color: var(--font-light);
  font-weight: var(--font-weight-thin);
}

body h1 {
  font-size: var(--h1-size);
}

h2 {
  font-size: var(--h2-size);
  font-weight: var(--font-weight-thin);
}

h3 {
  font-size: var(--h3-size);
  font-weight: var(--font-weight-thin);
}

h3.larger {
  font-size: var(--large-font-size);
  font-weight: var(--font-weight-bold);
}

body,
p,
select,
label {
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  font-variant-numeric: tabular-nums;
}

legend,
label,
.text-label,
.col-form-label {
  font-size: var(--font-size-heading);
  font-weight: var(--font-weight-bold);
}

small.text-muted {
  font-size: 0.9em;
  color: var(--font-help-text) !important;
  line-height: 1.5em;
}

.text-danger {
  color: var(--danger-dark) !important;
}

a {
  text-decoration: none;
  color: var(--link-color);
}

a:hover {
  color: var(--link-dark);
  text-decoration: underline;
}
