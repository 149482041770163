/* Overrides for tom-select */
.ts-control {
  border: 1px solid var(--light-gray);
  background-color: var(--input-bg);
  color: var(--font-color);
  font-weight: var(--font-weight);
}

.ts-control:hover {
  border: 1px solid var(--primary-color);
}

.focus .ts-control {
  border-color: var(--light-gray);
}

.ts-control,
.ts-wrapper.single.input-active .ts-control,
.full .ts-control {
  background: var(--input-bg);
}

.ts-dropdown .option,
.ts-dropdown .optgroup-header,
.ts-dropdown .no-results,
.ts-dropdown .create {
  border-bottom: 1px solid var(--light-gray);
}

.ts-dropdown,
.ts-dropdown.form-control {
  border: 1px solid var(--light-gray);
}

.plugin-dropdown_input .dropdown-input {
  border-color: var(--light-gray);
}

.plugin-dropdown_input.focus .ts-dropdown .dropdown-input {
  border-color: var(--light-gray);
}

.plugin-dropdown_input.focus.dropdown-active .ts-control {
  border-color: var(--primary-color);
}

.ts-dropdown-content {
  padding: 0;
}

/* Multi select */
.ts-wrapper.multi .ts-control > div.item {
  border-radius: 100px;
  background: var(--primary-color);
  line-height: 14px;
  font-weight: bold;
  font-size: var(--small-font-size);
  color: #fff;
  padding: 4px 5px 3px 11px !important;
  box-shadow: none !important;
  display: inline-block;
}

.ts-wrapper.plugin-remove_button .item .remove {
  border-left: none !important;
  background-color: var(--primary-color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!-- Font Awesome Pro 5.15.4 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E") !important;
  background-size: 11px 11px !important;
  background-position: center center !important;
  height: 11px;
  width: 11px;
  text-indent: -100000px;
  margin-left: 5px;
  margin-bottom: 2px;
}

.ts-wrapper.plugin-remove_button .item .remove:hover {
  background: var(--primary-color);
}

.plugin-clear_button.single .clear-button {
  right: calc(0.75rem - 5px + 1.2rem);
}
