.policy-block-collection fieldset.form-group {
  margin-bottom: 0;
}

.policy-block-desc {
  padding-top: 26px;
  line-height: 35px;
}

.condition,
.and-condition {
  border: none;
}

.condition {
  display: flex;
  width: 90%;
  line-height: 35px;
  padding: 0 0 var(--mid-spacing) 0;
  gap: 0 10px;
}

.condition > .form-group {
  flex-grow: 1;
  width: 100%;
}

.condition div .ts-control > input {
  min-width: 0;
}

.condition .btn-delete {
  padding-top: 3px;
}

.or-conditions .collection-item:last-child .or-condition-or {
  display: none;
}

.or-conditions .box-container > .btn-delete {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.condition .form-group {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .condition {
    flex-direction: column;
    gap: 10px 0;
  }

  .policy-block-desc {
    padding: 0 1.5rem 1.5rem 1.5rem;
    line-height: 1rem;
  }
}

.and-condition {
  padding: 0;
  padding-top: 20px;
}

.and-condition:first-child {
  padding-top: 0;
}

.or-condition {
  margin: 0;
  margin-bottom: 20px;
}

.or-condition:not(.last-has-margin):last-child {
  margin-bottom: 0;
}

.or-condition .collection-item {
  background: none;
}

.or-condition-or {
  display: none;
}

.and-condition:not(:first-child) .has-or:first-child .or-condition-or {
  display: block;
}

.condition:not(.has-and)::after {
  content: " and ";
}

.and-condition .add-and {
  display: block;
}

.and-condition:not(.has-inline-or)::after {
  content: " or ";
  position: relative;
  top: 10px;
}

.condition:not(.has-and):last-child::after,
.and-condition:last-child::after {
  content: "";
}

.condition.has-and:last-child .condition-and,
.only-collection-item-check:only-child > .btn-delete,
.only-collection-item-check:only-child > .delete-hold > .btn-delete,
.only-collection-item-check:only-child > .delete-hold > .delete-hold > .btn-delete,
.only-collection-item-check:only-child > .box-container > .btn-delete {
  visibility: hidden;
}

.condition:not(.has-and)::after,
.and-condition::after {
  font-size: 0.9em;
}

.and-condition .form-group {
  /* display: inline-block; */
  padding: 0;
}

.and-condition .form-group {
  margin: 0;
}

.condition .close {
  float: none;
  position: initial !important;
}

/* =========== modal ============== */

.modal-decision .alert {
  margin: 20px;
}
